if (document.body.classList.contains('page-template-template-configuratore'))
{

	const choices       = document.querySelectorAll('.choice');
	const machines      = document.querySelector('.machines');
	const prevButton    = document.querySelector('.configurator-nav .btn--prev');
	const nextButton    = document.querySelector('.configurator-nav .btn--next');
	const exportButton  = document.querySelector('.configurator-nav .btn--export');
	const restartButton = document.querySelector('.configurator-nav .btn--restart');

	const lastSection   = 5;
	let activeSection   = 1;
	let choicesSequence = [];

	choices.forEach(function (choice) {

		choice.addEventListener('click', function () {
			const section = this.closest('.configurator-section');

			section.querySelectorAll('.choice').forEach(function (el) {
				el.classList.remove('active');
			});

			this.classList.add('active');

			// enable next button
			nextButton.disabled = false;

			// if it's the last step...
			if (activeSection === lastSection)
			{
				const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
				const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
				const currentChoice  = currentSection.querySelector('.choice.active');

				// get image and title from currentChoice
				const currentChoiceImg   = currentChoice.querySelector('svg').cloneNode(true);
				const currentChoiceTitle = currentChoice.querySelector('.title').textContent;
				const currentChoiceValue = choice.dataset.choice;

				// show last step title
				currentStep.querySelector('.step__title').style.opacity = 1;

				// set title in current step with values from current choice
				currentStep.querySelector('.step__info > div > span').innerHTML = currentChoiceTitle;

				// change current step into past step
				currentStep.classList.remove('step--current');
				currentStep.classList.add('step--past');

				// if the user already made a choice in the last step, remove the last choice in the sequence before adding the new one
				if (choicesSequence.length === lastSection)
				{
					choicesSequence.pop();
				}

				// add new choice to sequence
				choicesSequence.push(currentChoiceValue);

				// show only notes related to chosen value
				currentSection.querySelectorAll('.note[data-show-if]').forEach(function (el) {

					const showIf = el.dataset.showIf.split(',');

					if (showIf.includes(currentChoiceValue))
					{
						el.style.display = 'block';
					}
					else
					{
						el.style.display = 'none';
					}

				});

				showMachines();

				exportButton.disabled = false;
			}
		});

	});


	nextButton.addEventListener('click', function () {
		const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const currentChoice  = currentSection.querySelector('.choice.active');

		// get image and title from currentChoice
		const currentChoiceImg   = currentChoice.querySelector('svg').cloneNode(true);
		const currentChoiceTitle = currentChoice.querySelector('.title').textContent;
		const currentChoiceValue = currentChoice.dataset.choice;

		// add current choice to choicesSequence
		choicesSequence.push(currentChoiceValue);

		// set title in current step with value from current choice
		currentStep.querySelector('.step__info > div > span').innerHTML = currentChoiceTitle;

		// change current step into past step
		currentStep.classList.remove('step--current');
		currentStep.classList.add('step--past');

		// hide current section
		currentSection.style.display = 'none';

		// set next section
		activeSection++;
		const nextSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const nextStep    = document.querySelector('.step[data-step="' + activeSection + '"]');

		// change next step into current step
		nextStep.classList.remove('step--future');
		nextStep.classList.add('step--current');

		// show next section
		nextSection.style.display = 'block';

		// show only choices related to chosen value
		nextSection.querySelectorAll('[data-show-if]').forEach(function (el) {

			// skip the machines
			if (el.closest('.machines') !== null)
			{
				return;
			}

			const showIf = el.dataset.showIf.split(',');

			if (showIf.includes(currentChoiceValue))
			{
				el.style.display = 'block';
			}

			// hide elements not related to the line choice
			if (el.hasAttribute('data-show-if-line'))
			{
				const showIfLine = el.dataset.showIfLine.split(',');

				if (!showIfLine.includes(choicesSequence[0]))
				{
					el.style.display = 'none';
				}
			}

		});

		// disable next button
		nextButton.disabled = true;

		// show prev button
		prevButton.style.display    = 'inline-flex';
		restartButton.style.display = 'inline-flex';

		// if it's the last step, hide next button and show export button
		if (activeSection === lastSection)
		{
			nextButton.style.display   = 'none';
			exportButton.style.display = 'inline-flex';
			exportButton.disabled      = true;

			// show machines if the first step choice was "new" and the last step choice was "one-embossing"
			if (choicesSequence[0] === 'new' && currentChoiceValue === 'one-embossing')
			{
				// show next step title
				nextStep.querySelector('.step__title').style.opacity = 1;

				// set title in next step with value = "not required"
				nextStep.querySelector('.step__info > div > span').innerHTML = 'Not needed';

				// change next step into past step
				nextStep.classList.remove('step--current');
				nextStep.classList.add('step--past');

				showMachines();
				exportButton.disabled = false;
			}
		}
	});


	prevButton.addEventListener('click', function () {
		const currentSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const currentStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const currentChoice  = currentSection.querySelector('.choice.active');

		// reset current choice
		if (currentChoice !== null)
		{
			currentChoice.classList.remove('active');
		}

		// if the user made a choice in the last section, remove the choice
		if (choicesSequence.length === lastSection)
		{
			choicesSequence.pop();
		}

		// remove last choice from choicesSequence
		choicesSequence.pop();

		// change current step into future step
		currentStep.classList.remove('step--current', 'step--past');
		currentStep.classList.add('step--future');

		// hide current section
		currentSection.style.display = 'none';

		// show only choices related to chosen value
		currentSection.querySelectorAll('[data-show-if]').forEach(function (el) {
			el.style.display = 'none';
		});

		// hide last step title and restore text in .step__info
		if (activeSection === lastSection)
		{
			currentStep.querySelector('.step__title').style.opacity         = 0;
			currentStep.querySelector('.step__info > div > span').innerHTML = currentStep.querySelector('.step__title').textContent;
		}

		// set prev section
		activeSection--;
		const prevSection = document.querySelector('.configurator-section[data-step="' + activeSection + '"]');
		const prevStep    = document.querySelector('.step[data-step="' + activeSection + '"]');
		const prevChoice  = prevSection.querySelector('.choice.active');

		// reset prev choice
		prevChoice.classList.remove('active');

		// restore title in prev step
		prevStep.querySelector('.step__info > div > span').innerHTML = prevStep.querySelector('.step__title').textContent;

		// change prev step into current step
		prevStep.classList.remove('step--past');
		prevStep.classList.add('step--current');

		// show prev section
		prevSection.style.display = 'block';

		// hide prev button if first section
		if (activeSection === 1)
		{
			prevButton.style.display    = 'none';
			restartButton.style.display = 'none';
		}

		// if it's the step before the last, show next button and hide export button
		if (activeSection === lastSection - 1)
		{
			nextButton.style.display   = 'inline-flex';
			nextButton.disabled        = true;
			exportButton.style.display = 'none';
		}
	});


	exportButton.addEventListener('click', function () {
		window.print();
	});


	restartButton.addEventListener('click', function () {
		location.reload();
	});


	function showMachines() {
		const choicesSequenceValues = choicesSequence.join(',');

		// show the machines related to the choices made, and remove the others
		machines.querySelectorAll('[data-show-if]').forEach(function (machine) {
			const showIf = machine.dataset.showIf.split('|');

			if (showIf.includes(choicesSequenceValues))
			{
				machine.style.display = 'block';
			}
			else
			{
				machine.style.display = 'none';
			}

			setTimeout(function () {
				machine.querySelectorAll('[data-show-if-line]').forEach(function (el) {

					const showIfLine = el.dataset.showIfLine.split(',');

					if (!showIfLine.includes(choicesSequence[0]))
					{
						el.style.display = 'none';
					}
					else
					{
						el.style.display = 'block';
					}

				});
			}, 50);

		});

		generateConfigurationQrcode(choicesSequenceValues);
	}

	/**
	 * Add selected documents to cookies
	 */
	let generateConfigurationQrcode = function (choicesSequenceValues) {

		const data = new FormData();
		data.append('action', 'wc_do_ajax');
		data.append('fn', 'generate_configuration_qrcode');
		data.append('choices', choicesSequenceValues);
		data.append('nonce', ajax_vars.nonce);

		fetch(ajax_vars.ajax_url, {
			method:      'POST',
			credentials: 'same-origin',
			body:        data
		})
			.then((response) => response.text())
			.then((data) => {
				if (data)
				{
					const parsedData = JSON.parse(data);

					const qrCode = document.querySelector('.qr-code');
					qrCode.insertAdjacentHTML('beforeend', parsedData);
					qrCode.style.display = 'flex';
				}

			})
			.catch((error) => {
				console.error(error);
			});
	};
}